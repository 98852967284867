import Vue from "vue";
import Vuex from "vuex";
import api, { navixy } from "../utils/api";
import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: 0,
    navixyTransport: [],
    ordersActiveProducts: null,
    ordersActiveProductsMain: null,
    ordersActive: null,
    allCars: null,
    orderFilterId: null,
    orderById: null,
    registration: null,
    addTransport: new FormData(),
    profileProducts: null,
    citiesProducts: null,
    periodList: null,
    positionsProducts: null,
    carsProducts: null,
    marksProducts: null,
    modelProducts: null,
    schedule: null,
    analytics: null,
    products: null,
    accounts: null,
    orderPoints: null,
    tasks: null,
    transportList: [],
    transport: null,
    malfunctions: null,
    notifications: null,
    employersList: null,
    driversList: null,
    driversListRegion: null,
    driverInfo: null,
    contragentsList: null,
    toastData: null,
    mapTransports: null,
    trackerId: null,
    regionList: null,
    countriesList: null,
    usersList: null,
    zoneShowData: null,
    driversById: null,
    imagesAdd: null,
    textFilter: null,
    contragentType: "",
    statusFilter: null,
    routeStatusFilter: null,
    addressFilter: null,
    routeAddressFilter: null,
    dateFilter: null,
    exportFilter: null,
    statusList: null,
    bodyList: null,
    orderExport: null,
    oilExport: null,
    routeHistoryPoints: [],
    OrderTrackEvents: [],
    CityZonePart: [],
    CityDeliveryZones: [],
    RoutesDrivers: [],
    typeFilter: null,
    insuranceList: [],
    purchaseList: [],
    productList: [],
    purchaseCategories: [],
    purchaseFields: [],
    serviceList: [],
    transportFuelReport: [],
    counterpartyList: null,
    searchPoints: [],
    directionsCost: null,
    transportModels: null
  },
  mutations: {
    /**
     * Used to set 'loading' state to desired number.
     *
     * Recommended to only use if you have to reset 'loading' state to 0.
     *
     * @param {number} payload Number to set 'loading' state to
     */
    setLoading(state, payload = 0) {
      state.loading = payload;
    },
    /**
     * Use this when you ADD a trigger that has to show the loading spinner.
     *
     * Always use decrementLoading() after using incrementLoading().
     */
    incrementLoading(state) {
      state.loading++;
    },
    /**
     * Use this when the you REMOVE a trigger that has had to show the loading spinner.
     *
     * Most of the times you should use incrementLoading() before using decrementLoading().
     */
    decrementLoading(state) {
      if (state.loading > 0) {
        state.loading--;
      }
    },
    setNavixyTransport(state, payload) {
      state.navixyTransport = payload;
    },
    setOrdersActive(state, payload) {
      state.ordersActive = payload.data.data;
    },
    setOrderPointsProducts(state, payload) {
      state.orderPointsProducts = payload.data.data;
    },
    setAllCars(state, payload) {
      state.allCars = payload.data.data;
    },
    setCityZonePart(state, payload) {
      state.CityZonePart = payload.data.data;
    },
    setCityDeliveryZones(state, payload) {
      state.CityDeliveryZones = payload.data.data;
    },
    setOrderPoints(state, payload) {
      state.orderPoints = payload.data;
    },
    setOrdersActiveProducts(state, payload) {
      state.ordersActiveProducts = payload.data;
    },
    setOrdersActiveProductsMain(state, payload) {
      state.ordersActiveProductsMain = payload.data;
    },
    setOrderFilterId(state, payload) {
      state.orderFilterId = payload.data.data;
      state.ordersActiveProducts = payload.data.data;
    },
    setOrderById(state, payload) {
      payload ? (state.orderById = payload.data.data) : (state.orderById = null);
    },
    setRegistration(state, payload) {
      state.registration = payload;
    },
    setAddTransport(state, payload) {
      state.addTransport = payload;
    },
    setProfileProducts(state, payload) {
      state.profileProducts = payload.data.data;
    },
    logout(state) {
      state.profileProducts = null;
    },
    setCitiesProducts(state, payload) {
      state.citiesProducts = payload.data.data;
    },
    setPeriodList(state, payload) {
      state.periodList = payload.data.data;
    },
    setPositionsProducts(state, payload) {
      state.positionsProducts = payload;
    },
    setCarsProducts(state, payload) {
      state.carsProducts = payload.data.data;
    },
    setMarksProducts(state, payload) {
      state.marksProducts = payload.data.data;
    },
    setModelProducts(state, payload) {
      state.modelProducts = payload.data.data;
    },
    setSchedule(state, payload) {
      state.schedule = payload.data.data;
    },
    setAnalytics(state, payload) {
      state.analytics = payload.data.data;
    },
    setProducts(state, payload) {
      state.products = payload.data;
    },
    setAccounts(state, payload) {
      state.accounts = payload.data.data;
    },
    setTasks(state, payload) {
      state.tasks = payload.data.data;
    },
    setTransportList(state, payload) {
      state.transportList = payload;
    },
    setTransport(state, payload) {
      state.transport = payload;
    },
    textFilter(state, payload) {
      state.textFilter = payload;
    },
    contragentType(state, payload) {
      state.contragentType = payload;
    },
    statusFilter(state, payload) {
      state.statusFilter = payload;
    },
    routeStatusFilter(state, payload) {
      state.routeStatusFilter = payload;
    },
    addressFilter(state, payload) {
      state.addressFilter = payload;
    },
    routeAddressFilter(state, payload) {
      state.routeAddressFilter = payload;
    },
    dateFilter(state, payload) {
      state.dateFilter = payload;
    },
    exportFilter(state, payload) {
      state.exportFilter = payload;
    },
    typeFilter(state, payload) {
      state.typeFilter = payload;
    },
    setMalfunctions(state, payload) {
      state.malfunctions = payload.data.data;
    },
    setNotifications(state, payload) {
      state.notifications = payload.data.data;
    },
    setEmployersList(state, payload) {
      state.employersList = payload.data.data;
    },
    setContragentsList(state, payload) {
      state.contragentsList = payload.data.data;
    },
    setToastData(state, payload) {
      state.toastData = payload;
    },
    setMapTransports(state, payload) {
      state.mapTransports = payload;
    },
    setTrackerId(state, payload) {
      state.trackerId = payload;
    },
    setRegionList(state, payload) {
      state.regionList = payload;
    },
    setCountriesList(state, payload) {
      state.countriesList = payload;
    },
    setDriversList(state, payload) {
      state.driversList = payload;
    },
    setDriversListRegion(state, payload) {
      state.driversListRegion = payload.data.data;
    },
    setDriverInfo(state, payload) {
      state.driverInfo = payload;
    },
    setDriversById(state, payload) {
      state.driversById = payload.data;
    },
    setZoneShowData(state, payload) {
      state.zoneShowData = payload;
    },
    setUsersList(state, payload) {
      state.usersList = payload;
    },
    imagesAdd(state, payload) {
      state.imagesAdd = payload;
    },
    setStatusList(state, payload) {
      state.statusList = payload.data;
    },
    setBodyList(state, payload) {
      state.bodyList = payload.data;
    },
    setOrderExport(state, payload) {
      state.orderExport = payload.data;
    },
    setOilExport(state, payload) {
      state.oilExport = payload.data;
    },
    setRouteHistoryPoints(state, payload) {
      state.routeHistoryPoints = payload;
    },
    setOrderTrackEvents(state, payload) {
      state.OrderTrackEvents = payload;
    },
    setRoutesDrivers(state, payload) {
      state.RoutesDrivers = payload;
    },
    updateDriverHealth(state, payload) {
      state.RoutesDrivers[payload.index] = payload
    },
    setInsuranceList(state, payload) {
      state.insuranceList = payload.data
    },
    setPurchaseList(state, payload) {
      state.purchaseList = payload.data
    },
    setProductList(state, payload) {
      state.productList = payload.data
    },
    setPurchaseCategories(state, payload) {
      state.purchaseCategories = payload.data
    },
    setPurchaseFields(state, payload) {
      state.purchaseFields = payload.data
    },
    setServiceList(state, payload) {
      state.serviceList = payload.data
    },
    setTransportFuelReport(state, payload) {
      state.transportFuelReport = payload.data
    },
    setCounterpartyList(state, payload) {
      state.counterpartyList = payload
    },
    setSearchPoints(state, payload) {
      state.searchPoints = payload;
    },
    setDirectionsCost(state, payload) {
      state.directionsCost = payload;
    },
    setTransportModels(state, payload) {
      state.transportModels = payload;
    },
  },
  actions: {
    async getNavixyTransport({ commit }) {
      await navixy
        .get("/tracker/list", {
          params: {
            hash: Cookies.get("navixy_hash"),
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            commit("setNavixyTransport", response.data.list);
          } else console.error("Не удалось получить транспорт (navixy api)");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getRouteHistoryPoints({ commit }, payload) {
      commit("incrementLoading");
      try {
        const response = await Promise.resolve(
          api.get(
            `/web/order-track-history?from=${payload.from}&to=${payload.to}&label=${payload.label}&tracker_id=${payload.trackerId}`
          )
        );
        if (response.data.success === false) {
          throw new Error(response.data.status.description);
        }
        commit("setRouteHistoryPoints", response.data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getRouteHistoryPoints, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },
    async getOrdersActive({ commit }) {
      try {
        const data = await Promise.resolve(
          api.get("/web/order-points", {
            params: {
              status: "active",
            },
          })
        );
        commit("setOrdersActive", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error(
          "Внутреняя ошибка сервера: getOrdersActive, сообщите администратору: ",
          err
        );
      }
    },
    async getOrdersActiveProducts({ commit }, payload) {
      commit("incrementLoading");
      try {
        const data = await Promise.resolve(
          api.get("/web/active-orders", {
            params: payload,
          })
        );
        commit("setOrdersActiveProducts", data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getOrdersActiveProducts, сообщите администратору"
        );
      } finally {
        commit("decrementLoading");
      }
    },
    async getOrdersActiveProductsMain({ commit }, payload) {
      commit("incrementLoading");
      try {
        const data = await Promise.resolve(
          api.get("/web/active-orders-main", {
            params: payload,
          })
        );
        commit("setOrdersActiveProductsMain", data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getOrdersActiveProductsMain, сообщите администратору"
        );
      } finally {
        commit("decrementLoading");
      }
    },
    async getAllCars({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/web/transports"));
        commit("setAllCars", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getAllCars, сообщите администратору");
      }
    },
    async getCityZoneParts({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/web/zones"));
        commit("setCityZonePart", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getAllCars, сообщите администратору");
      }
    },
    async getCityDeliveryZones({ commit }, payload) {
      try {
        const data = await Promise.resolve(api.get(`/web/delivery-zones/${payload}`));
        commit("setCityDeliveryZones", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getCityDeliveryZones, сообщите администратору");
      }
    },
    async getOrderPoints({ commit }, payload) {
      try {
        const data = await Promise.resolve(
          api.get("/web/order-points", {
            params: payload,
          })
        );
        commit("setOrderPoints", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getOrderPoints, сообщите администратору", err);
      }
    },
    async postOrderFilterId({ commit }, payload) {
      commit("incrementLoading");
      try {
        const data = await Promise.resolve(api.post("/web/active-orders-filter"), payload);
        commit("setOrderFilterId", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: postOrderFilterId, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },
    async getOrderById({ commit }, payload) {
      commit("incrementLoading");
      try {
        const data = await Promise.resolve(api.get(`/web/order-by-id/${payload}`));
        commit("setOrderById", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getOrderById, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },
    // async postAddTransport (_, payload) {
    //   try {
    //     await Promise.resolve(api.post('/web/add-transport', payload))
    //     // commit('setAddTransport', payload, data)
    //   } catch (err) {
    //     // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
    //     // throw new Error('Внутреняя ошибка сервера, сообщите администратору')
    //   }
    // },
    async getProfileProducts({ commit }) {
      commit("incrementLoading");
      try {
        const data = await Promise.resolve(api.get("/web/profile"));
        commit("setProfileProducts", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getProfileProducts, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },
    async getCitiesProducts({ commit }, payload) {
      try {
        const data = await Promise.resolve(api.get("/v1/cities", {
          params: payload,
        }));
        commit("setCitiesProducts", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getCitiesProducts, сообщите администратору");
      }
    },
    async getPeriodList({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/v1/period"));
        commit("setPeriodList", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getCitiesProducts, сообщите администратору");
      }
    },
    async getPositionsProducts({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/v1/positions"));
        commit("setPositionsProducts", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getPositionsProducts, сообщите администратору");
      }
    },
    async getSchedule({ commit }, payload) {
      try {
        const data = await Promise.resolve(
          api.get("/web/schedule", {
            params: payload,
          })
        );
        commit("setSchedule", data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getSchedule, сообщите администратору");
      }
    },
    async getAnalytics({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/web/analytics/expense"));
        commit("setAnalytics", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getAnalytics, сообщите администратору");
      }
    },
    async getProducts({ commit }, payload) {
      try {
        const data = await Promise.resolve(
          api.get("/web/analytics/points", {
            params: payload,
          })
        );
        commit("setProducts", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getAnalytics, сообщите администратору");
      }
    },
    async getAccounts({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/web/expenses-accounts"));
        commit("setAccounts", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getAccounts, сообщите администратору");
      }
    },
    async getTasks({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/web/tasks"));
        commit("setTasks", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getTasks, сообщите администратору");
      }
    },
    async getCarsProducts({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/v1/cars"));
        commit("setCarsProducts", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getCarsProducts, сообщите администратору");
      }
    },
    async getMarksProducts({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/v1/marks"));
        commit("setMarksProducts", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getMarksProducts, сообщите администратору");
      }
    },
    async getModelProducts({ commit }, payload) {
      try {
        const data = await Promise.resolve(api.get(`/v1/models/${payload}`));
        commit("setModelProducts", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getModelProducts, сообщите администратору");
      }
    },
    async getTransportModels({ commit }) {
      try {
        const data = await Promise.resolve(api.get(`/v1/models-get`));
        commit("setTransportModels", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getTransportModels, сообщите администратору");
      }
    },
    async getMalfunctions({ commit }) {
      commit("incrementLoading");
      try {
        const data = await Promise.resolve(api.get("/web/malfunctions"));
        commit("setMalfunctions", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        // throw new Error(
        //   "Внутреняя ошибка сервера: getMalfunctions, сообщите администратору"
        // );
        console.error(err);
      } finally {
        commit("decrementLoading");
      }
    },
    async getNotifications({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/v1/notifications"));
        commit("setNotifications", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getNotifications, сообщите администратору");
      }
    },
    async getTransportList({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(api.get("/web/transports", { params: payload }));
        payload && payload.type === 'get_with_paginate'
          ? commit("setTransportList", data)
          : commit("setTransportList", data.data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getTransportList, сообщите администратору");
      }
    },
    async getTransport({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(api.get(`/web/transport/show/${payload}`));
        commit("setTransport", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getTransport, сообщите администратору");
      }
    },
    async getEmployersList({ commit }) {
      try {
        const data = await Promise.resolve(api.get("/web/employers"));
        commit("setEmployersList", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getEmployersList, сообщите администратору");
      }
    },
    async getContragentsList({ commit }) {
      commit("incrementLoading");
      try {
        const data = await Promise.resolve(api.get("/web/contragents"));
        commit("setContragentsList", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getContragentsList, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },
    async getMapTransports({ commit }) {
      try {
        const { data } = await Promise.resolve(api.get("/web/map-transports"));
        commit("setMapTransports", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        console.error(err);
        throw new Error("Внутреняя ошибка сервера: getMapTransports, сообщите администратору");
      }
    },
    async getTrackerId({ commit }, payload) {
      commit("incrementLoading");
      try {
        const { data } = await Promise.resolve(api.post("/transport", payload));
        commit("setTrackerId", data);
      } catch (err) {
        commit("setTrackerId", "false");
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getTrackerId, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },
    async getRegionList({ commit }) {
      try {
        const { data } = await Promise.resolve(api.get("/v1/regions"));
        commit("setRegionList", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getRegionList, сообщите администратору");
      }
    },
    async getCountriesList({ commit }) {
      try {
        const { data } = await Promise.resolve(api.get("/v1/countries"));
        commit("setCountriesList", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getCountriesList, сообщите администратору");
      }
    },
    async getBodyList({ commit }) {
      try {
        const { data } = await Promise.resolve(api.get("/v1/transport-body"));
        commit("setBodyList", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getBodyList, сообщите администратору");
      }
    },
    async getUsersList({ commit }) {
      try {
        const { data } = await Promise.resolve(api.get("/v1/empty-users"));
        commit("setUsersList", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getUsersList, сообщите администратору");
      }
    },
    async getDriversList({ commit }) {
      try {
        const { data } = await Promise.resolve(api.get("/web/users"));
        commit("setDriversList", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getDriversList, сообщите администратору");
      }
    },
    async getDriversListRegion({ commit }) {
      commit("incrementLoading");
      try {
        const data = await Promise.resolve(api.get("/web/users"));
        commit("setDriversListRegion", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getContragentsList, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },
    async updateDriversList({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(api.get(`/web/search-drivers?value=${payload.value}`));
        commit("setDriversList", data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: updateDriversList, сообщите администратору");
      }
    },
    async getDriverInfo({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(api.get("/web/get-driver-info", { params: { id: payload } }));
        commit("setDriverInfo", data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getDriverInfo сообщите администратору");
      }
    },
    async getDriversById({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(
          api.get("/web/user-show", {
            params: payload,
          })
        );
        commit("setDriversById", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getDriversById, сообщите администратору");
      }
    },
    async getStatusList({ commit }) {
      try {
        const { data } = await Promise.resolve(api.get("/web/status"));
        commit("setStatusList", data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getStatusList, сообщите администратору");
      }
    },
    async getOrderExport({ commit }) {
      commit("incrementLoading");
      try {
        const { data } = await Promise.resolve(api.get("/web/point-export"));
        commit("setOrderExport", data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getOrderExport, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },
    async getOilExport({ commit }) {
      commit("incrementLoading");
      try {
        const { data } = await Promise.resolve(api.get("/web/oil-export"));
        commit("setOilExport", data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getOilExport, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },

    async getTransportFuelReport({ commit }) {
      commit("incrementLoading");
      try {
        const { data } = await Promise.resolve(api.get("/web/get-transport-oil-report"));
        commit("setTransportFuelReport", data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getTransportFuelReport, сообщите администратору");
      } finally {
        commit("decrementLoading");
      }
    },

    async getOrderTrackEvents({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(api.post("/web/get-track-events", payload));
        commit("setOrderTrackEvents", data);
      } catch (err) {
        if (err.response.status !== 404)
          throw new Error("Внутреняя ошибка сервера: getOrderTrackEvents, сообщите администратору");
      }
    },

    async getRoutesDrivers({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(api.get("/web/get-routes?page_number=" + payload.pageNumber));
        commit("setRoutesDrivers", data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getRoutesDrivers, сообщите администратору");
      }
    },

    async updateRoutesDrivers({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(api.get("/web/search-routes-drivers?value=" + payload.value));
        commit("setRoutesDrivers", data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: updateRoutesDrivers , сообщите администратору");
      }
    },

    async updateDriverHealth({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(api.post('/web/update-driver-health', payload));
        if (data) {
          commit('updateDriverHealth', payload);
        } else {
          console.error('Не удалось обновить данныеб  сообщите администратору');
        }
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: updateDriverHealth, сообщите администратору");
      }
    },

    async getCounterpartyList({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(api.get('/v2/counterparty', payload));
        if (data) {
          commit('setCounterpartyList', data);
        } else {
          console.error('Не удалось получить контраргентов  сообщите администратору');
        }
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: setCounterpartyList, сообщите администратору");
      }
    },

    async getInsuranceList({ commit }, payload) {
      try {
        const data = await Promise.resolve(
          api.get("/web/insurance",
            { params: payload },
          )
        );
        commit("setInsuranceList", data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getInsuranceList.",
          err
        );
      }
    },
    async getPurchaseList({ commit }, payload) {
      try {
        const data = await Promise.resolve(
          api.get("/web/purchase",
            { params: payload },
          )
        );
        commit("setPurchaseList", data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getPurchaseList.",
          err
        );
      }
    },
    async getProductList({ commit }, payload) {
      try {
        const data = await Promise.resolve(
          api.get("/web/product",
            { params: payload },
          )
        );
        commit("setProductList", data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getProductList.",
          err
        );
      }
    },
    async getPurchaseCategories({ commit }, payload) {
      try {
        const { data } = await Promise.resolve(
          api.get("/web/get-purchase-category",
            { params: payload },
          )
        );
        commit("setPurchaseCategories", data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getPurchaseCategories.",
          err
        );
      }
    },
    async getPurchaseFields({ commit }, payload) {
      try {
        const data = await Promise.resolve(api.get(`/web/get-purchase-fields/${payload}`));
        commit("setPurchaseFields", data);
      } catch (err) {
        // router.push(`/error/${err.message.split(' ').reverse()[0]}`)
        throw new Error("Внутреняя ошибка сервера: getPurchaseFields, сообщите администратору");
      }
    },
    async getServiceList({ commit }, payload) {
      try {
        const data = await Promise.resolve(
          api.get("/web/services",
            { params: payload },
          )
        );
        commit("setServiceList", data);
      } catch (err) {
        throw new Error(
          "Внутреняя ошибка сервера: getServiceList.",
          err
        );
      }
    },
    async getSearchPoints({ commit }, payload) {
      try {
        const res = await Promise.resolve(
          api.get("/web/search-points", {
            params: payload,
          })
        );
        commit("setSearchPoints", res.data);
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getSearchPoints, сообщите администратору", err);
      }
    },
    async getDirectionsCost({ commit }, payload) {
      try {
        if ( payload && payload.id) {
          const res = await Promise.resolve(api.get("/v2/directions-cost/" + payload.id));
          commit("setDirectionsCost", res.data)
        } else {
          const res = await Promise.resolve(api.get("/v2/directions-cost"));
          commit("setDirectionsCost", res.data)
        }
      } catch (err) {
        throw new Error("Внутреняя ошибка сервера: getDirectionsCost, сообщите администратору", err);
      }
    },
    async logout({ commit }) {
        commit("logout");
    },
  },
getters: {
  TOAST_DATA: (state) => state.toastData,
    getRoutesDrivers: (state) => (id) => {
      return state.RoutesDrivers[id];
    },
      getDriverInfo(state) {
    return state.driverInfo;
  }
},
  // plugins: [createPersistedState()]
});
